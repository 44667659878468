import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '11, 44, 98',
		'primary-dark': '153, 153, 153',
		'accent': '254, 200, 0',
		'accent-plus': '7, 7, 7',
	},
});
